import request from '../util/request'

// 1.获取申报指南列表
export const getReportingGuidelinesList = (params) => {
  return request({
    url: '/api/demo/ProjectGov/list',
    method: 'post',
    data: {
      release_time_seven: params.release_time_seven || '',
      pro_id: params.pro_id || '',
      project_industry_id: params.project_industry_id || '',
      release_time_thirty: params.release_time_thirty || '',
      type_id: params.type_id || '',
      project_status_end: params.project_status_end || '',
      project_status: params.project_status || '',
      help_money_start: params.help_money_start || '',
      help_money_end: params.help_money_end || '',
      limit: params.limit || '',
      page: params.page || '',
      title: params.title || ''
    }

  })
}
// 获取课题类型
export const getProjectTypes = () => {
  return request({
    url: '/api/demo/ProjectGov/type',
    method: 'get'

  })
}
// 获取研究领域
export const getResearchField = () => {
  return request({
    url: '/api/demo/ProjectGov/industry',
    method: 'get'

  })
}
// 获取资助地区
export const getSupportArea = () => {
  return request({
    url: '/api/demo/ProjectGov/pro',
    method: 'get'

  })
}
// 获取关键词列表
export const getKeywords = (params) => {
  return request({
    url: '/api/demo/subscribe/key_words',
    method: 'post',
    data: {
      keywords: params
    }

  })
}
// 获取非会员页面地址
export const getPage = () => {
  return request({
    url: '/api/demo/search/page',
    method: 'get'

  })
}
